"use client";

import { SidebarFooter, SidebarMenu, SidebarMenuItem, SidebarMenuButton } from "../ui/sidebar";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuTrigger } from "~/app/_components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { ChevronsUpDown, LogOut } from "lucide-react";
import { signOut, useSession } from "next-auth/react";
export default function MainSidebarFooter() {
  const {
    data: session
  } = useSession();
  const initials = session?.user.initials || session?.user.userName.split(" ").map(name => name[0]).join("");
  return <SidebarFooter data-sentry-element="SidebarFooter" data-sentry-component="MainSidebarFooter" data-sentry-source-file="MainSidebarFooter.tsx">
      <SidebarMenu data-sentry-element="SidebarMenu" data-sentry-source-file="MainSidebarFooter.tsx">
        <SidebarMenuItem data-sentry-element="SidebarMenuItem" data-sentry-source-file="MainSidebarFooter.tsx">
          {/* Dev tools */}
          {/* <button onClick={() => console.log(session)}>test sessoin</button> */}
          <DropdownMenu data-sentry-element="DropdownMenu" data-sentry-source-file="MainSidebarFooter.tsx">
            <DropdownMenuTrigger asChild data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="MainSidebarFooter.tsx">
              <SidebarMenuButton size="lg" className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground" data-sentry-element="SidebarMenuButton" data-sentry-source-file="MainSidebarFooter.tsx">
                <Avatar className="h-8 w-8 rounded-lg" data-sentry-element="Avatar" data-sentry-source-file="MainSidebarFooter.tsx">
                  {/* <AvatarImage src={data.user.avatar} alt={data.user.name} /> */}
                  <AvatarFallback className="rounded-lg text-oxford" data-sentry-element="AvatarFallback" data-sentry-source-file="MainSidebarFooter.tsx">
                    {initials}
                  </AvatarFallback>
                </Avatar>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">
                    {session?.user.userName}
                  </span>
                  <span className="truncate text-xs">
                    {session?.user.userEmail}
                  </span>
                </div>
                <ChevronsUpDown className="ml-auto size-4" data-sentry-element="ChevronsUpDown" data-sentry-source-file="MainSidebarFooter.tsx" />
              </SidebarMenuButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-[--radix-dropdown-menu-trigger-width] min-w-16 rounded-lg" side="bottom" align="end" sideOffset={4} data-sentry-element="DropdownMenuContent" data-sentry-source-file="MainSidebarFooter.tsx">
              {/* <DropdownMenuLabel className="p-0 font-normal">
                <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                  <Avatar className="h-8 w-8 rounded-lg">
                    <AvatarImage src={data.user.avatar} alt={data.user.name} />
                    <AvatarFallback className="rounded-lg">CN</AvatarFallback>
                  </Avatar>
                  <div className="grid flex-1 text-left text-sm leading-tight">
                    <span className="truncate font-semibold">
                      {data.user.name}
                    </span>
                    <span className="truncate text-sm">{data.user.email}</span>
                  </div>
                </div>
               </DropdownMenuLabel> */}
              {/* <DropdownMenuSeparator />
               <DropdownMenuGroup>
                <DropdownMenuItem>
                  <Sparkles />
                  Upgrade to Pro
                </DropdownMenuItem>
               </DropdownMenuGroup>
               <DropdownMenuSeparator />
               <DropdownMenuGroup>
                <DropdownMenuItem>
                  <BadgeCheck />
                  Account
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <CreditCard />
                  Billing
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Bell />
                  Notifications
                </DropdownMenuItem>
               </DropdownMenuGroup>
               <DropdownMenuSeparator /> */}
              <DropdownMenuItem onClick={() => signOut({
              callbackUrl: "/auth/login"
            })} className="text-sm" data-sentry-element="DropdownMenuItem" data-sentry-source-file="MainSidebarFooter.tsx">
                <LogOut size={16} data-sentry-element="LogOut" data-sentry-source-file="MainSidebarFooter.tsx" />
                Log out
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarFooter>;
}