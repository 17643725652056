"use client";

import { SidebarHeader, SidebarMenu, SidebarMenuItem, SidebarMenuButton, SidebarTrigger } from "../ui/sidebar";
import Image from "next/image";
export default function MainSidebarHeader() {
  return <SidebarHeader data-sentry-element="SidebarHeader" data-sentry-component="MainSidebarHeader" data-sentry-source-file="MainSidebarHeader.tsx">
      <div className="sticky right-0 top-2 -mr-5 text-right">
        <SidebarTrigger className="absolute rotate-180" data-sentry-element="SidebarTrigger" data-sentry-source-file="MainSidebarHeader.tsx" />
      </div>
      <SidebarMenu data-sentry-element="SidebarMenu" data-sentry-source-file="MainSidebarHeader.tsx">
        <SidebarMenuItem data-sentry-element="SidebarMenuItem" data-sentry-source-file="MainSidebarHeader.tsx">
          <SidebarMenuButton variant={`static`} size="lg" asChild data-sentry-element="SidebarMenuButton" data-sentry-source-file="MainSidebarHeader.tsx">
            <a href="/">
              <div className="bg-sidebar-primary text-sidebar-primary-foreground flex aspect-square size-8 items-center justify-center rounded-lg">
                <Image src="/logo/logo-alt.png" alt="Spott Logo" width={40} height={40} data-sentry-element="Image" data-sentry-source-file="MainSidebarHeader.tsx" />
              </div>
              <div className="flex items-end gap-1 leading-none">
                <span className="font-polysansmedian text-xl">Spott</span>
                {/* <span className="inline">v1.0.0</span> */}
              </div>
            </a>
          </SidebarMenuButton>
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarHeader>;
}