import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"fonts/Polysans-slim.woff2\",\"variable\":\"--font-polysans-slim\",\"display\":\"swap\"}],\"variableName\":\"polysansSlim\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"fonts/Polysans-neutral.woff2\",\"variable\":\"--font-polysans-neutral\",\"display\":\"swap\"}],\"variableName\":\"polysansNeutral\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"fonts/Polysans-median.woff2\",\"variable\":\"--font-polysans-median\",\"display\":\"swap\"}],\"variableName\":\"polysansMedian\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCReactProvider"] */ "/app/src/_trpc/react.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/app/src/app/_components/Auth/AuthContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/_components/Errors/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/_components/Sidebar/MainSidebarWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
